// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-article-dir-js": () => import("/Users/olivier/Sites/professeurb/src/components/article-dir.js" /* webpackChunkName: "component---src-components-article-dir-js" */),
  "component---src-components-article-layout-js": () => import("/Users/olivier/Sites/professeurb/src/components/article-layout.js" /* webpackChunkName: "component---src-components-article-layout-js" */),
  "component---src-pages-404-js": () => import("/Users/olivier/Sites/professeurb/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/olivier/Sites/professeurb/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/Users/olivier/Sites/professeurb/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

