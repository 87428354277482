import React from 'react'
import { Line } from '@nivo/line'
import range from 'lodash/range'

const commonProperties = {
    width: 300,
    height: 170,
    margin: { top: 20, right: 20, bottom: 50, left: 20 },
    animate: true,
    enablePoints: false,
    enableSlice: false,
    curve: 'linear',
    useMesh: true,
    isInteractive: true,
};

function Courbe0() {
    let data = [{ x: 0x5f376400, y: 0.0342157520353794097900391 },
    { x: 0x5f376401, y: 0.0342156924307346343994141 },
    { x: 0x5f376402, y: 0.0342156365513801574707031 },
    { x: 0x5f376403, y: 0.0342155769467353820800781 },
    { x: 0x5f376404, y: 0.0342155210673809051513672 },
    { x: 0x5f376405, y: 0.0342154614627361297607422 },
    { x: 0x5f376406, y: 0.0342154055833816528320312 },
    { x: 0x5f376407, y: 0.0342153497040271759033203 },
    { x: 0x5f376408, y: 0.0342152900993824005126953 },
    { x: 0x5f376409, y: 0.0342151969671249389648438 },
    { x: 0x5f37640a, y: 0.0342151373624801635742188 },
    { x: 0x5f37640b, y: 0.0342150814831256866455078 },
    { x: 0x5f37640c, y: 0.0342150218784809112548828 },
    { x: 0x5f37640d, y: 0.0342149659991264343261719 },
    { x: 0x5f37640e, y: 0.0342149063944816589355469 },
    { x: 0x5f37640f, y: 0.0342148505151271820068359 },
    { x: 0x5f376410, y: 0.0342147909104824066162109 },
    { x: 0x5f376411, y: 0.0342147350311279296875000 },
    { x: 0x5f376412, y: 0.0342146381735801696777344 },
    { x: 0x5f376413, y: 0.0342145822942256927490234 },
    { x: 0x5f376414, y: 0.0342145226895809173583984 },
    { x: 0x5f376415, y: 0.0342144668102264404296875 },
    { x: 0x5f376416, y: 0.0342144072055816650390625 },
    { x: 0x5f376417, y: 0.0342143513262271881103516 },
    { x: 0x5f376418, y: 0.0342142954468727111816406 },
    { x: 0x5f376419, y: 0.0342142358422279357910156 },
    { x: 0x5f37641a, y: 0.0342141799628734588623047 },
    { x: 0x5f37641b, y: 0.0342140831053256988525391 },
    { x: 0x5f37641c, y: 0.0342140272259712219238281 },
    { x: 0x5f37641d, y: 0.0342139676213264465332031 },
    { x: 0x5f37641e, y: 0.0342139117419719696044922 },
    { x: 0x5f37641f, y: 0.0342138521373271942138672 },
    { x: 0x5f376420, y: 0.0342137962579727172851562 },
    { x: 0x5f376421, y: 0.0342137366533279418945312 },
    { x: 0x5f376422, y: 0.0342136807739734649658203 },
    { x: 0x5f376423, y: 0.0342136211693286895751953 },
    { x: 0x5f376424, y: 0.0342135280370712280273438 },
    { x: 0x5f376425, y: 0.0342134684324264526367188 },
    { x: 0x5f376426, y: 0.0342134125530719757080078 },
    { x: 0x5f376427, y: 0.0342133529484272003173828 },
    { x: 0x5f376428, y: 0.0342132970690727233886719 },
    { x: 0x5f376429, y: 0.0342132411897182464599609 },
    { x: 0x5f37642a, y: 0.0342131815850734710693359 },
    { x: 0x5f37642b, y: 0.0342131257057189941406250 },
    { x: 0x5f37642c, y: 0.0342130661010742187500000 },
    { x: 0x5f37642d, y: 0.0342129729688167572021484 },
    { x: 0x5f37642e, y: 0.0342129133641719818115234 },
    { x: 0x5f37642f, y: 0.0342128872871398925781250 },
    { x: 0x5f376430, y: 0.0342129841446876525878906 },
    { x: 0x5f376431, y: 0.0342130772769451141357422 },
    { x: 0x5f376432, y: 0.0342131741344928741455078 },
    { x: 0x5f376433, y: 0.0342132709920406341552734 },
    { x: 0x5f376434, y: 0.0342133641242980957031250 },
    { x: 0x5f376435, y: 0.0342134609818458557128906 },
    { x: 0x5f376436, y: 0.0342135578393936157226562 },
    { x: 0x5f376437, y: 0.0342136509716510772705078 },
    { x: 0x5f376438, y: 0.0342137478291988372802734 },
    { x: 0x5f376439, y: 0.0342138446867465972900391 },
    { x: 0x5f37643a, y: 0.0342139378190040588378906 },
    { x: 0x5f37643b, y: 0.0342140346765518188476562 },
    { x: 0x5f37643c, y: 0.0342141315340995788574219 },
    { x: 0x5f37643d, y: 0.0342142246663570404052734 },
    { x: 0x5f37643e, y: 0.0342143215239048004150391 },
    { x: 0x5f37643f, y: 0.0342144183814525604248047 },
    { x: 0x5f376440, y: 0.0342145115137100219726562 },
    { x: 0x5f376441, y: 0.0342146083712577819824219 },
    { x: 0x5f376442, y: 0.0342147052288055419921875 },
    { x: 0x5f376443, y: 0.0342148020863533020019531 },
    { x: 0x5f376444, y: 0.0342148952186107635498047 },
    { x: 0x5f376445, y: 0.0342149920761585235595703 },
    { x: 0x5f376446, y: 0.0342150889337062835693359 },
    { x: 0x5f376447, y: 0.0342151820659637451171875 },
    { x: 0x5f376448, y: 0.0342152789235115051269531 },
    { x: 0x5f376449, y: 0.0342153757810592651367188 },
    { x: 0x5f37644a, y: 0.0342154689133167266845703 },
    { x: 0x5f37644b, y: 0.0342155657708644866943359 },
    { x: 0x5f37644c, y: 0.0342156626284122467041016 },
    { x: 0x5f37644d, y: 0.0342157557606697082519531 },
    { x: 0x5f37644e, y: 0.0342158526182174682617188 },
    { x: 0x5f37644f, y: 0.0342159494757652282714844 }]
    return (
        <Line
            {...commonProperties}
            data={[{ data: data }]}
            xScale={{
                type: "linear",
                min: 'auto',
                max: 'auto',
            }}
            axisBottom={{
                tickValues: [0x5f37642f],
                format: value =>
                    `0x${Number(value).toString(16)}`,
            }}
            axisTop={{
                tickValues: [],
                legend: '0 itération',
                legendOffset: -15,
                legendPosition: 'middle'
            }}
            yScale={{
                type: "linear",
                min: 0.0342126,
                max: 'auto'
            }}
            axisLeft={{
                tickValues: []
            }}
            tooltip={({ point }) => {
                // console.log(point)
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <table>
                            <tr style={{ fontSize: 'small', borderStyle: 'none' }}>
                                <td style={{ borderStyle: 'none' }}>Nombre&nbsp;magique</td>
                                <td style={{ fontSize: 'small', fontFamily: 'Source Code Pro', borderStyle: 'none' }}>0x{(point.data.x).toString(16)}</td>
                            </tr>
                            <tr style={{ fontSize: 'small', borderStyle: 'none' }}>
                                <td style={{ borderStyle: 'none' }}>Erreur maximale</td>
                                <td style={{ fontSize: 'small', fontFamily: 'Source Code Pro', borderStyle: 'none' }}>{String(point.data.yFormatted).substr(0, 12)}</td>
                            </tr>
                        </table>
                    </div>
                );
            }}
        />
    )
}

export default Courbe0
