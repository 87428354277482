import React from 'react'
import { Line } from '@nivo/line'
import range from 'lodash/range'

const commonProperties = {
    width: 300,
    height: 170,
    margin: { top: 20, right: 20, bottom: 50, left: 20 },
    animate: true,
    enablePoints: false,
    enableSlice: false,
    curve: 'linear',
    useMesh: true,
    isInteractive: true,
};

function Courbe2() {
    let data = [
        { x: 0x5f375a02, y: 0.0000047287649067584425211 },
        { x: 0x5f375a03, y: 0.0000047287649067584425211 },
        { x: 0x5f375a04, y: 0.0000047287608140322845429 },
        { x: 0x5f375a05, y: 0.0000047287649067584425211 },
        { x: 0x5f375a06, y: 0.0000047287649067584425211 },
        { x: 0x5f375a07, y: 0.0000047287649067584425211 },
        { x: 0x5f375a08, y: 0.0000047691473810118623078 },
        { x: 0x5f375a09, y: 0.0000047287649067584425211 },
        { x: 0x5f375a0a, y: 0.0000047691473810118623078 },
        { x: 0x5f375a0b, y: 0.0000047287562665587756783 },
        { x: 0x5f375a0c, y: 0.0000047691473810118623078 },
        { x: 0x5f375a0d, y: 0.0000047691473810118623078 },
        { x: 0x5f375a0e, y: 0.0000047691473810118623078 },
        { x: 0x5f375a0f, y: 0.0000047691473810118623078 },
        { x: 0x5f375a10, y: 0.0000047691473810118623078 },
        { x: 0x5f375a11, y: 0.0000047691473810118623078 },
        { x: 0x5f375a12, y: 0.0000047691473810118623078 },
        { x: 0x5f375a13, y: 0.0000047287562665587756783 },
        { x: 0x5f375a14, y: 0.0000047691473810118623078 },
        { x: 0x5f375a15, y: 0.0000047287649067584425211 },
        { x: 0x5f375a16, y: 0.0000047691473810118623078 },
        { x: 0x5f375a17, y: 0.0000047287562665587756783 },
        { x: 0x5f375a18, y: 0.0000047287517190852668136 },
        { x: 0x5f375a19, y: 0.0000047284706852224189788 },
        { x: 0x5f375a1a, y: 0.0000047284747779485769570 },
        { x: 0x5f375a1b, y: 0.0000047284747779485769570 },
        { x: 0x5f375a1c, y: 0.0000047287430788855999708 },
        { x: 0x5f375a1d, y: 0.0000047284747779485769570 },
        { x: 0x5f375a1e, y: 0.0000047284747779485769570 },
        { x: 0x5f375a1f, y: 0.0000047284706852224189788 },
        { x: 0x5f375a20, y: 0.0000047287430788855999708 },
        { x: 0x5f375a21, y: 0.0000047284615902754012495 },
        { x: 0x5f375a22, y: 0.0000047284747779485769570 },
        { x: 0x5f375a23, y: 0.0000047284706852224189788 },
        { x: 0x5f375a24, y: 0.0000047287476263591088355 },
        { x: 0x5f375a25, y: 0.0000047284615902754012495 },
        { x: 0x5f375a26, y: 0.0000047287430788855999708 },
        { x: 0x5f375a27, y: 0.0000047278958845708984882 },
        { x: 0x5f375a28, y: 0.0000047287476263591088355 },
        { x: 0x5f375a29, y: 0.0000047287476263591088355 },
        { x: 0x5f375a2a, y: 0.0000047678445298515725881 },
        { x: 0x5f375a2b, y: 0.0000047287476263591088355 },
        { x: 0x5f375a2c, y: 0.0000047678445298515725881 },
        { x: 0x5f375a2d, y: 0.0000047678445298515725881 },
        { x: 0x5f375a2e, y: 0.0000047678445298515725881 },
        { x: 0x5f375a2f, y: 0.0000047678445298515725881 },
        { x: 0x5f375a30, y: 0.0000047678445298515725881 },
        { x: 0x5f375a31, y: 0.0000047678445298515725881 },
        { x: 0x5f375a32, y: 0.0000047678445298515725881 },
        { x: 0x5f375a33, y: 0.0000047678445298515725881 },
        { x: 0x5f375a34, y: 0.0000047287476263591088355 },
        { x: 0x5f375a35, y: 0.0000047287430788855999708 },
        { x: 0x5f375a36, y: 0.0000047287476263591088355 },
        { x: 0x5f375a37, y: 0.0000047287476263591088355 },
        { x: 0x5f375a38, y: 0.0000047287476263591088355 },
        { x: 0x5f375a39, y: 0.0000047281723709602374583 },
        { x: 0x5f375a3a, y: 0.0000047287476263591088355 },
        { x: 0x5f375a3b, y: 0.0000047281723709602374583 },
        { x: 0x5f375a3c, y: 0.0000047287476263591088355 },
        { x: 0x5f375a3d, y: 0.0000047281723709602374583 },
        { x: 0x5f375a3e, y: 0.0000047768321564944926649 },
        { x: 0x5f375a3f, y: 0.0000047281723709602374583 },
        { x: 0x5f375a40, y: 0.0000047768321564944926649 },
        { x: 0x5f375a41, y: 0.0000047281632760132197291 },
        { x: 0x5f375a42, y: 0.0000047768321564944926649 },
        { x: 0x5f375a43, y: 0.0000047768321564944926649 },
        { x: 0x5f375a44, y: 0.0000047768321564944926649 },
        { x: 0x5f375a45, y: 0.0000047284488573495764285 },
        { x: 0x5f375a46, y: 0.0000047768321564944926649 },
        { x: 0x5f375a47, y: 0.0000047284529500757344067 },
        { x: 0x5f375a48, y: 0.0000047768321564944926649 },
        { x: 0x5f375a49, y: 0.0000047638122850912623107 },
        { x: 0x5f375a4a, y: 0.0000047638122850912623107 },
        { x: 0x5f375a4b, y: 0.0000047284488573495764285 },
        { x: 0x5f375a4c, y: 0.0000047707039811939466745 },
        { x: 0x5f375a4d, y: 0.0000047638122850912623107 },
        { x: 0x5f375a4e, y: 0.0000047707039811939466745 },
        { x: 0x5f375a4f, y: 0.0000047707039811939466745 },
        { x: 0x5f375a50, y: 0.0000047707039811939466745 },
        { x: 0x5f375a51, y: 0.0000047707039811939466745 },
        { x: 0x5f375a52, y: 0.0000047733065002830699086 },
        { x: 0x5f375a53, y: 0.0000047707039811939466745 },
        { x: 0x5f375a54, y: 0.0000047733065002830699086 },
        { x: 0x5f375a55, y: 0.0000047747485041327308863 },
        { x: 0x5f375a56, y: 0.0000047767080104677006602 },
    ]

    return (
        <Line
            {...commonProperties}
            data={[{ data: data }]}
            xScale={{
                type: "linear",
                min: 'auto',
                max: 'auto',
            }}
            axisBottom={{
                tickValues: [0x5f375a27],
                format: value =>
                    `0x${Number(value).toString(16)}`,
                }}
                axisTop={{
                    tickValues: [],
                    legend: '2 itérations',
                    legendOffset: -15,
                    legendPosition: 'middle'
                }}
                yScale={{
                type: "linear",
                min: 0.000004725,
                max: 'auto'
            }}
            axisLeft={{
                tickValues: []
            }}
            tooltip={({ point }) => {
                // console.log(point)
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <table>
                            <tr style={{ fontSize: 'small', borderStyle: 'none' }}>
                                <td style={{ borderStyle: 'none' }}>Nombre&nbsp;magique</td>
                                <td style={{ fontSize: 'small', fontFamily: 'Source Code Pro', borderStyle: 'none' }}>0x{(point.data.x).toString(16)}</td>
                            </tr>
                            <tr style={{ fontSize: 'small', borderStyle: 'none' }}>
                                <td style={{ borderStyle: 'none' }}>Erreur maximale</td>
                                <td style={{ fontSize: 'small', fontFamily: 'Source Code Pro', borderStyle: 'none' }}>{String(point.data.yFormatted).substr(0, 13)}</td>
                            </tr>
                        </table>
                    </div>
                );
            }}
        />
    )
}

export default Courbe2
