import fig1 from "../../../../src/content/ipt/spe/gravity/fig1.svg";
import fig2 from "../../../../src/content/ipt/spe/gravity/fig2.svg";
import fig3 from "../../../../src/content/ipt/spe/gravity/fig3.svg";
import fig4 from "../../../../src/content/ipt/spe/gravity/fig4.svg";
import Center from 'components/Center.js';
import React from 'react';
export default {
  fig1,
  fig2,
  fig3,
  fig4,
  Center,
  React
};