module.exports = [{
      plugin: require('/Users/olivier/Sites/professeurb/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/olivier/Sites/professeurb/src/components/layout.js"},"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files"}],"rehypePlugins":[null,null,null],"remarkPlugins":[null]},
    },{
      plugin: require('/Users/olivier/Sites/professeurb/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121104091-1"},
    },{
      plugin: require('/Users/olivier/Sites/professeurb/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
