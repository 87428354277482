import SortBox from "../../../../src/content/articles/tris/SortBox.js";
import SelectSortBox from "../../../../src/content/articles/tris/SelectSort.js";
import InsertSortBox from "../../../../src/content/articles/tris/InsertSort.js";
import MergeSortBox from "../../../../src/content/articles/tris/MergeSort.js";
import QuickSortBox from "../../../../src/content/articles/tris/QuickSort.js";
import TimSortBox from "../../../../src/content/articles/tris/TimSort.js";
import HeapSortBox from "../../../../src/content/articles/tris/HeapSort.js";
import React from 'react';
export default {
  SortBox,
  SelectSortBox,
  InsertSortBox,
  MergeSortBox,
  QuickSortBox,
  TimSortBox,
  HeapSortBox,
  React
};