import Grid1 from "../../../../src/content/articles/sudoku/grid1.js";
import Grid2 from "../../../../src/content/articles/sudoku/grid2.js";
import Grid3 from "../../../../src/content/articles/sudoku/grid3.js";
import Center from "components/Center.js";
import React from 'react';
export default {
  Grid1,
  Grid2,
  Grid3,
  Center,
  React
};