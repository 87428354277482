import React from 'react'
import { Line } from '@nivo/line'
import range from 'lodash/range'

const commonProperties = {
    width: 300,
    height: 170,
    margin: { top: 20, right: 20, bottom: 50, left: 20 },
    animate: true,
    enablePoints: false,
    enableSlice: false,
    curve: 'linear',
    useMesh: true,
    isInteractive: true,
};

function Courbe1() {
    let data = [
        { x: 0x5f375a5b, y: 0.0017515618819743394851685 },
        { x: 0x5f375a5c, y: 0.0017515597864985466003418 },
        { x: 0x5f375a5d, y: 0.0017515588551759719848633 },
        { x: 0x5f375a5e, y: 0.0017515567597001791000366 },
        { x: 0x5f375a5f, y: 0.0017515558283776044845581 },
        { x: 0x5f375a60, y: 0.0017515537329018115997314 },
        { x: 0x5f375a61, y: 0.0017515528015792369842529 },
        { x: 0x5f375a62, y: 0.0017515567597001791000366 },
        { x: 0x5f375a63, y: 0.0017515497747808694839478 },
        { x: 0x5f375a64, y: 0.0017515537329018115997314 },
        { x: 0x5f375a65, y: 0.0017514580395072698593140 },
        { x: 0x5f375a66, y: 0.0017515508225187659263611 },
        { x: 0x5f375a67, y: 0.0017514550127089023590088 },
        { x: 0x5f375a68, y: 0.0017514590872451663017273 },
        { x: 0x5f375a69, y: 0.0017514517530798912048340 },
        { x: 0x5f375a6a, y: 0.0017514560604467988014221 },
        { x: 0x5f375a6b, y: 0.0017514487262815237045288 },
        { x: 0x5f375a6c, y: 0.0017514530336484313011169 },
        { x: 0x5f375a6d, y: 0.0017514456994831562042236 },
        { x: 0x5f375a6e, y: 0.0017514497740194201469421 },
        { x: 0x5f375a6f, y: 0.0017514426726847887039185 },
        { x: 0x5f375a70, y: 0.0017514467472210526466370 },
        { x: 0x5f375a71, y: 0.0017514396458864212036133 },
        { x: 0x5f375a72, y: 0.0017514437204226851463318 },
        { x: 0x5f375a73, y: 0.0017514366190880537033081 },
        { x: 0x5f375a74, y: 0.0017514406936243176460266 },
        { x: 0x5f375a75, y: 0.0017514396458864212036133 },
        { x: 0x5f375a76, y: 0.0017514376668259501457214 },
        { x: 0x5f375a77, y: 0.0017514366190880537033081 },
        { x: 0x5f375a78, y: 0.0017513459315523505210876 },
        { x: 0x5f375a79, y: 0.0017513448838144540786743 },
        { x: 0x5f375a7a, y: 0.0017513429047539830207825 },
        { x: 0x5f375a7b, y: 0.0017513418570160865783691 },
        { x: 0x5f375a7c, y: 0.0017513398779556155204773 },
        { x: 0x5f375a7d, y: 0.0017513388302177190780640 },
        { x: 0x5f375a7e, y: 0.0017513366183266043663025 },
        { x: 0x5f375a7f, y: 0.0017513356870040297508240 },
        { x: 0x5f375a80, y: 0.0017513398779556155204773 },
        { x: 0x5f375a81, y: 0.0017513326602056622505188 },
        { x: 0x5f375a82, y: 0.0017513305647298693656921 },
        { x: 0x5f375a83, y: 0.0017513296334072947502136 },
        { x: 0x5f375a84, y: 0.0017513276543468236923218 },
        { x: 0x5f375a85, y: 0.0017513266066089272499084 },
        { x: 0x5f375a86, y: 0.0017513305647298693656921 },
        { x: 0x5f375a87, y: 0.0017513271886855363845825 },
        { x: 0x5f375a88, y: 0.0017513373168185353279114 },
        { x: 0x5f375a89, y: 0.0017513384809717535972595 },
        { x: 0x5f375a8a, y: 0.0017513493075966835021973 },
        { x: 0x5f375a8b, y: 0.0017513621132820844650269 },
        { x: 0x5f375a8c, y: 0.0017513709608465433120728 },
        { x: 0x5f375a8d, y: 0.0017513836501166224479675 },
        { x: 0x5f375a8e, y: 0.0017513954080641269683838 },
        { x: 0x5f375a8f, y: 0.0017513937782496213912964 },
        { x: 0x5f375a90, y: 0.0017514179926365613937378 },
        { x: 0x5f375a91, y: 0.0017514193896204233169556 },
        { x: 0x5f375a92, y: 0.0017514274222776293754578 },
        { x: 0x5f375a93, y: 0.0017514497740194201469421 },
        { x: 0x5f375a94, y: 0.0017514502396807074546814 },
        { x: 0x5f375a95, y: 0.0017514636274427175521851 },
        { x: 0x5f375a96, y: 0.0017514727078378200531006 },
        { x: 0x5f375a97, y: 0.0017514812061563134193420 },
        { x: 0x5f375a98, y: 0.0017514884239062666893005 },
        { x: 0x5f375a99, y: 0.0017515025101602077484131 },
        { x: 0x5f375a9a, y: 0.0017515091458335518836975 },
        { x: 0x5f375a9b, y: 0.0017515182262286543846130 },
        { x: 0x5f375a9c, y: 0.0017515405779704451560974 },
        { x: 0x5f375a9d, y: 0.0017515391809865832328796 },
        { x: 0x5f375a9e, y: 0.0017515544313937425613403 },
        { x: 0x5f375a9f, y: 0.0017515635117888450622559 },
        { x: 0x5f375aa0, y: 0.0017515708459541201591492 },
        { x: 0x5f375aa1, y: 0.0017515842337161302566528 },
        { x: 0x5f375aa2, y: 0.0017515933141112327575684 },
    ]
    return (
        <Line
            {...commonProperties}
            data={[{ data: data }]}
            xScale={{
                type: "linear",
                min: 'auto',
                max: 'auto',
            }}
            axisBottom={{
                tickValues: [0x5f375a85],
                format: value =>
                    `0x${Number(value).toString(16)}`,
                }}
                axisTop={{
                    tickValues: [],
                    legend: '1 itération',
                    legendOffset: -15,
                    legendPosition: 'middle'
                }}
                yScale={{
                type: "linear",
                min: 0.0017513,
                max: 'auto'
            }}
            axisLeft={{
                tickValues: []
            }}
            tooltip={({ point }) => {
                // console.log(point)
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <table>
                            <tr style={{ fontSize: 'small', borderStyle: 'none' }}>
                                <td style={{ borderStyle: 'none' }}>Nombre&nbsp;magique</td>
                                <td style={{ fontSize: 'small', fontFamily: 'Source Code Pro', borderStyle: 'none' }}>0x{(point.data.x).toString(16)}</td>
                            </tr>
                            <tr style={{ fontSize: 'small', borderStyle: 'none' }}>
                                <td style={{ borderStyle: 'none' }}>Erreur maximale</td>
                                <td style={{ fontSize: 'small', fontFamily: 'Source Code Pro', borderStyle: 'none' }}>{String(point.data.yFormatted).substr(0, 12)}</td>
                            </tr>
                        </table>
                    </div>
                );
            }}
        />
    )
}

export default Courbe1
