import Additor from "../../../../src/content/articles/chiffres_binaires/truc1.jsx";
import Binop from "../../../../src/content/articles/chiffres_binaires/truc2.jsx";
import DeBruijn from "../../../../src/content/articles/chiffres_binaires/truc3.jsx";
import Center from "components/Center.js";
import React from 'react';
export default {
  Additor,
  Binop,
  DeBruijn,
  Center,
  React
};