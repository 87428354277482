import React from 'react'
import { Line } from '@nivo/line'
import range from 'lodash/range'

const commonProperties = {
    width: 652,
    height: 400,
    margin: { top: 20, right: 40, bottom: 50, left: 60 },
    animate: true,
    enablePoints: false,
    curve: 'basis',
    enableSlices: 'x',
    colors: { scheme: 'nivo' },
};

function Logo() {
    let data1 = [
        { x: 1593507840, y: 0.3068414926528930664062500 },
        { x: 1593573376, y: 0.3040294647216796875000000 },
        { x: 1593638912, y: 0.3012287914752960205078125 },
        { x: 1593704448, y: 0.2984392642974853515625000 },
        { x: 1593769984, y: 0.2956607937812805175781250 },
        { x: 1593835520, y: 0.2928932011127471923828125 },
        { x: 1593901056, y: 0.2873903810977935791015625 },
        { x: 1593966592, y: 0.2819296717643737792968750 },
        { x: 1594032128, y: 0.2765102088451385498046875 },
        { x: 1594097664, y: 0.2711310386657714843750000 },
        { x: 1594163200, y: 0.2657912671566009521484375 },
        { x: 1594228736, y: 0.2604900300502777099609375 },
        { x: 1594294272, y: 0.2552265822887420654296875 },
        { x: 1594359808, y: 0.2500000298023223876953125 },
        { x: 1594425344, y: 0.2448096424341201782226562 },
        { x: 1594490880, y: 0.2396546900272369384765625 },
        { x: 1594556416, y: 0.2345344871282577514648438 },
        { x: 1594621952, y: 0.2294482439756393432617188 },
        { x: 1594687488, y: 0.2243954092264175415039062 },
        { x: 1594753024, y: 0.2193752676248550415039062 },
        { x: 1594818560, y: 0.2143871784210205078125000 },
        { x: 1594884096, y: 0.2094305753707885742187500 },
        { x: 1594949632, y: 0.2045048922300338745117188 },
        { x: 1595015168, y: 0.1996094882488250732421875 },
        { x: 1595080704, y: 0.1947438269853591918945312 },
        { x: 1595146240, y: 0.1899074465036392211914062 },
        { x: 1595211776, y: 0.1850997060537338256835938 },
        { x: 1595277312, y: 0.1803202033042907714843750 },
        { x: 1595342848, y: 0.1755684018135070800781250 },
        { x: 1595408384, y: 0.1708438098430633544921875 },
        { x: 1595473920, y: 0.1661459952592849731445312 },
        { x: 1595539456, y: 0.1614745259284973144531250 },
        { x: 1595604992, y: 0.1568289101123809814453125 },
        { x: 1595670528, y: 0.1522087454795837402343750 },
        { x: 1595736064, y: 0.1476136296987533569335938 },
        { x: 1595801600, y: 0.1430431902408599853515625 },
        { x: 1595867136, y: 0.1384969651699066162109375 },
        { x: 1595932672, y: 0.1339745968580245971679688 },
        { x: 1595998208, y: 0.1294757574796676635742188 },
        { x: 1596063744, y: 0.1250000447034835815429688 },
        { x: 1596129280, y: 0.1205470860004425048828125 },
        { x: 1596194816, y: 0.1161165609955787658691406 },
        { x: 1596260352, y: 0.1117081195116043090820312 },
        { x: 1596325888, y: 0.1073214858770370483398438 },
        { x: 1596391424, y: 0.1029562801122665405273438 },
        { x: 1596456960, y: 0.0986121892929077148437500 },
        { x: 1596522496, y: 0.0942889451980590820312500 },
        { x: 1596588032, y: 0.0899862796068191528320312 },
        { x: 1596653568, y: 0.0857038348913192749023438 },
        { x: 1596719104, y: 0.0814413800835609436035156 },
        { x: 1596784640, y: 0.0771986022591590881347656 },
        { x: 1596850176, y: 0.0729752033948898315429688 },
        { x: 1596915712, y: 0.0687709823250770568847656 },
        { x: 1596981248, y: 0.0645856931805610656738281 },
        { x: 1597046784, y: 0.0604189895093441009521484 },
        { x: 1597112320, y: 0.0562707334756851196289062 },
        { x: 1597177856, y: 0.0521405935287475585937500 },
        { x: 1597243392, y: 0.0480284094810485839843750 },
        { x: 1597308928, y: 0.0439338684082031250000000 },
        { x: 1597374464, y: 0.0398568026721477508544922 },
        { x: 1597440000, y: 0.0357970073819160461425781 },
        { x: 1597505536, y: 0.0380319505929946899414062 },
        { x: 1597571072, y: 0.0443166941404342651367188 },
        { x: 1597636608, y: 0.0506140701472759246826172 },
        { x: 1597702144, y: 0.0569240525364875793457031 },
        { x: 1597767680, y: 0.0632466226816177368164062 },
        { x: 1597833216, y: 0.0695817470550537109375000 },
        { x: 1597898752, y: 0.0759293958544731140136719 },
        { x: 1597964288, y: 0.0822895616292953491210938 },
        { x: 1598029824, y: 0.0886622071266174316406250 }]
    let data2 = [
        { x: 1593507840, y: 0.1267827451229095458984375 },
        { x: 1593573376, y: 0.1245995983481407165527344 },
        { x: 1593638912, y: 0.1224416494369506835937500 },
        { x: 1593704448, y: 0.1203086376190185546875000 },
        { x: 1593769984, y: 0.1182003691792488098144531 },
        { x: 1593835520, y: 0.1161165162920951843261719 },
        { x: 1593901056, y: 0.1120216324925422668457031 },
        { x: 1593966592, y: 0.1080220416188240051269531 },
        { x: 1594032128, y: 0.1041161715984344482421875 },
        { x: 1594097664, y: 0.1003023982048034667968750 },
        { x: 1594163200, y: 0.0965790897607803344726562 },
        { x: 1594228736, y: 0.0929448381066322326660156 },
        { x: 1594294272, y: 0.0893981158733367919921875 },
        { x: 1594359808, y: 0.0859375298023223876953125 },
        { x: 1594425344, y: 0.0825617462396621704101562 },
        { x: 1594490880, y: 0.0792693868279457092285156 },
        { x: 1594556416, y: 0.0760591998696327209472656 },
        { x: 1594621952, y: 0.0729299113154411315917969 },
        { x: 1594687488, y: 0.0698804482817649841308594 },
        { x: 1594753024, y: 0.0669095143675804138183594 },
        { x: 1594818560, y: 0.0640159845352172851562500 },
        { x: 1594884096, y: 0.0611988306045532226562500 },
        { x: 1594949632, y: 0.0584569759666919708251953 },
        { x: 1595015168, y: 0.0557893253862857818603516 },
        { x: 1595080704, y: 0.0531949065625667572021484 },
        { x: 1595146240, y: 0.0506727956235408782958984 },
        { x: 1595211776, y: 0.0482219457626342773437500 },
        { x: 1595277312, y: 0.0458415113389492034912109 },
        { x: 1595342848, y: 0.0435305573046207427978516 },
        { x: 1595408384, y: 0.0412881709635257720947266 },
        { x: 1595473920, y: 0.0391135625541210174560547 },
        { x: 1595539456, y: 0.0370059125125408172607422 },
        { x: 1595604992, y: 0.0349643640220165252685547 },
        { x: 1595670528, y: 0.0329881161451339721679688 },
        { x: 1595736064, y: 0.0310765132308006286621094 },
        { x: 1595801600, y: 0.0292286332696676254272461 },
        { x: 1595867136, y: 0.0274439081549644470214844 },
        { x: 1595932672, y: 0.0257214270532131195068359 },
        { x: 1595998208, y: 0.0240607652813196182250977 },
        { x: 1596063744, y: 0.0224610026925802230834961 },
        { x: 1596129280, y: 0.0209215767681598663330078 },
        { x: 1596194816, y: 0.0194418318569660186767578 },
        { x: 1596260352, y: 0.0180211439728736877441406 },
        { x: 1596325888, y: 0.0166588425636291503906250 },
        { x: 1596391424, y: 0.0153544135391712188720703 },
        { x: 1596456960, y: 0.0141071826219558715820312 },
        { x: 1596522496, y: 0.0129165807738900184631348 },
        { x: 1596588032, y: 0.0117820361629128456115723 },
        { x: 1596653568, y: 0.0107030849903821945190430 },
        { x: 1596719104, y: 0.0096790166571736335754395 },
        { x: 1596784640, y: 0.0087094614282250404357910 },
        { x: 1596850176, y: 0.0077938376925885677337646 },
        { x: 1596915712, y: 0.0069316565059125423431396 },
        { x: 1596981248, y: 0.0061223590746521949768066 },
        { x: 1597046784, y: 0.0053654811345040798187256 },
        { x: 1597112320, y: 0.0046605998650193214416504 },
        { x: 1597177856, y: 0.0040072137489914894104004 },
        { x: 1597243392, y: 0.0034047630615532398223877 },
        { x: 1597308928, y: 0.0028529609553515911102295 },
        { x: 1597374464, y: 0.0023513159248977899551392 },
        { x: 1597440000, y: 0.0018993124831467866897583 },
        { x: 1597505536, y: 0.0021972672548145055770874 },
        { x: 1597571072, y: 0.0029895855113863945007324 },
        { x: 1597636608, y: 0.0039076246321201324462891 },
        { x: 1597702144, y: 0.0049528758972883224487305 },
        { x: 1597767680, y: 0.0061268135905265808105469 },
        { x: 1597833216, y: 0.0074309906922280788421631 },
        { x: 1597898752, y: 0.0088668987154960632324219 },
        { x: 1597964288, y: 0.0104360785335302352905273 },
        { x: 1598029824, y: 0.0121400728821754455566406 }]
    let data3 = [
        { x: 1593507840, y: 0.0230919290333986282348633 },
        { x: 1593573376, y: 0.0223204400390386581420898 },
        { x: 1593638912, y: 0.0215701870620250701904297 },
        { x: 1593704448, y: 0.0208406634628772735595703 },
        { x: 1593769984, y: 0.0201313626021146774291992 },
        { x: 1593835520, y: 0.0194417648017406463623047 },
        { x: 1593901056, y: 0.0181204900145530700683594 },
        { x: 1593966592, y: 0.0168729536235332489013672 },
        { x: 1594032128, y: 0.0156960375607013702392578 },
        { x: 1594097664, y: 0.0145863741636276245117188 },
        { x: 1594163200, y: 0.0135409496724605560302734 },
        { x: 1594228736, y: 0.0125567279756069183349609 },
        { x: 1594294272, y: 0.0116308694705367088317871 },
        { x: 1594359808, y: 0.0107606435194611549377441 },
        { x: 1594425344, y: 0.0099433399736881256103516 },
        { x: 1594490880, y: 0.0091764926910400390625000 },
        { x: 1594556416, y: 0.0084575619548559188842773 },
        { x: 1594621952, y: 0.0077843107283115386962891 },
        { x: 1594687488, y: 0.0071543254889547824859619 },
        { x: 1594753024, y: 0.0065656807273626327514648 },
        { x: 1594818560, y: 0.0060159531421959400177002 },
        { x: 1594884096, y: 0.0055034244433045387268066 },
        { x: 1594949632, y: 0.0050260168500244617462158 },
        { x: 1595015168, y: 0.0045819748193025588989258 },
        { x: 1595080704, y: 0.0041693956591188907623291 },
        { x: 1595146240, y: 0.0037866402417421340942383 },
        { x: 1595211776, y: 0.0034320876002311706542969 },
        { x: 1595277312, y: 0.0031040690373629331588745 },
        { x: 1595342848, y: 0.0028011808171868324279785 },
        { x: 1595408384, y: 0.0025219859089702367782593 },
        { x: 1595473920, y: 0.0022650014143437147140503 },
        { x: 1595539456, y: 0.0020288941450417041778564 },
        { x: 1595604992, y: 0.0018124731723219156265259 },
        { x: 1595670528, y: 0.0016144164837896823883057 },
        { x: 1595736064, y: 0.0014337534084916114807129 },
        { x: 1595801600, y: 0.0012691046576946973800659 },
        { x: 1595867136, y: 0.0011195285478606820106506 },
        { x: 1595932672, y: 0.0009839724516496062278748 },
        { x: 1595998208, y: 0.0008615410770289599895477 },
        { x: 1596063744, y: 0.0007512285956181585788727 },
        { x: 1596129280, y: 0.0006521053146570920944214 },
        { x: 1596194816, y: 0.0005634053959511220455170 },
        { x: 1596260352, y: 0.0004843547649215906858444 },
        { x: 1596325888, y: 0.0004140697710681706666946 },
        { x: 1596391424, y: 0.0003519345773383975028992 },
        { x: 1596456960, y: 0.0002972273796331137418747 },
        { x: 1596522496, y: 0.0002493065258022397756577 },
        { x: 1596588032, y: 0.0002075313386740162968636 },
        { x: 1596653568, y: 0.0001713409146759659051895 },
        { x: 1596719104, y: 0.0001401703339070081710815 },
        { x: 1596784640, y: 0.0001135390630224719643593 },
        { x: 1596850176, y: 0.0000910607705009169876575 },
        { x: 1596915712, y: 0.0000720505195204168558121 },
        { x: 1596981248, y: 0.0000562103632546495646238 },
        { x: 1597046784, y: 0.0000432412634836509823799 },
        { x: 1597112320, y: 0.0000326359368045814335346 },
        { x: 1597177856, y: 0.0000241877824009861797094 },
        { x: 1597243392, y: 0.0000174861961568240076303 },
        { x: 1597308928, y: 0.0000123182417155476287007 },
        { x: 1597374464, y: 0.0000084710100054508075118 },
        { x: 1597440000, y: 0.0000055281179811572656035 },
        { x: 1597505536, y: 0.0000074023701017722487450 },
        { x: 1597571072, y: 0.0000135624532049405388534 },
        { x: 1597636608, y: 0.0000230470886890543624759 },
        { x: 1597702144, y: 0.0000368975888704881072044 },
        { x: 1597767680, y: 0.0000563551075174473226070 },
        { x: 1597833216, y: 0.0000827984913485124707222 },
        { x: 1597898752, y: 0.0001177404701593331992626 },
        { x: 1597964288, y: 0.0001629706093808636069298 },
        { x: 1598029824, y: 0.0002203475305577740073204 }]


    return (
        <Line
            {...commonProperties}
            data={
                [
                    { id: "0 itération", data: data1 },
                    { id: "1 itération", data: data2 },
                    { id: "2 itérations", data: data3 }
                ]
            }
            xScale={{
                type: "linear",
                min: 1593507840,
                max: 1598029824,
            }}
            axisBottom={{
                tickValues: 2,
                format: value =>
                    `0x${Number(value).toString(16)}`,
                orient: 'bottom',
                legend: 'Nombre magique',
                legendOffset: 35,
                legendPosition: 'middle'
            }}

            yScale={{
                type: 'log',
                base: 10,
                max: 0.000001,
                stacked: true
            }}
            axisLeft={{
                tickValues: [0.1, 0.01, 0.001, 0.0001, 0.00001, 0.000001],
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Erreur relative',
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            // lineWidth={4}
            sliceTooltip={({ slice }) => {
                return (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                        }}
                    >
                        <div><strong style={{ fontSize: 'medium', fontFamily: 'Source Code Pro' }}>0x{(1593507840 + 8192 * slice.id).toString(16)}</strong></div>
                        <table>
                            {slice.points.map(point => (
                                <tr key={point.id} style={{ fontSize: 'small', borderStyle: 'none' }}>
                                    <td style={{ color: point.color, borderStyle: 'none' }}>&#9632;</td>
                                    <td style={{ borderStyle: 'none' }}>{point.serieId}</td>
                                    <td style={{ fontSize: 'small', fontFamily: 'Source Code Pro', borderStyle: 'none' }}>{String(point.data.yFormatted).substr(0, 10)}</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                );
            }}
        />
    )
}

export default Logo
