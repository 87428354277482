import Logu from "../../../../src/content/articles/carmack/log.jsx";
import Logo from "../../../../src/content/articles/carmack/log2.jsx";
import Floto from "../../../../src/content/articles/carmack/float.jsx";
import Courbe0 from "../../../../src/content/articles/carmack/courbe0.jsx";
import Courbe1 from "../../../../src/content/articles/carmack/courbe1.jsx";
import Courbe2 from "../../../../src/content/articles/carmack/courbe2.jsx";
import Center from "components/Center.js";
import React from 'react';
export default {
  Logu,
  Logo,
  Floto,
  Courbe0,
  Courbe1,
  Courbe2,
  Center,
  React
};